body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e073a;
  background-color: #f7f7fa;
  min-height: 100vh;
}
#root {
  height: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
